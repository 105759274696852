import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import netlifyIdentity from 'netlify-identity-widget';

import logo from '../images/gatsby-icon.png';

const NavLink = props => <Link getProps={isActive} {...props} />;
const isActive = ({ isCurrent }) => {
  return { className: isCurrent ? 'active' : 'navlink' };
}

class Header extends Component {
  componentDidMount() {
    netlifyIdentity.init();
  }

  render() {
    const { siteTitle } = this.props;

    return (
      <header
        style={{
          background: `rebeccapurple`,
          marginBottom: `1.45rem`,
        }}
      >
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `1.45rem 1.0875rem`,
            display: `flex`,
            flexDirection: `row`,
            alignItems: `center`,
          }}
        >
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="F.E.R.E.S corporate logo" style={{
                  height: '3rem',
                  margin: '0 1rem 0 0',
                  borderRadius: '50%',
                  border: '0.2rem solid orange',
                }} />
                <h1 style={{ margin: 0 }}>
                    <NavLink
                      to="/"
                      style={{
                        color: `white`,
                        textDecoration: `none`,
                      }}
                    >
                      {siteTitle}
                    </NavLink>
                </h1>
            </span>

          <span style={{
            display: 'flex',
            flex: 1,
            justifyContent: `space-around`,
          }}>
              <NavLink to="/page-2">Page 2</NavLink>
              <NavLink to="/page-3">Page 3</NavLink>
              <NavLink to="/articles">Article</NavLink>
              <NavLink to="/blog">Blog</NavLink>
          </span>

          <div data-netlify-identity-menu />
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
