import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

const ArticleTemplate = ({ data: article }) => (
  <Layout>
    <div>
      <h2>{article.contentfulArticle.title} - <span style={{color: '#ccc'}}>Published on {article.contentfulArticle.publishDate}</span></h2>
      <p>{article.contentfulArticle.description}</p>
      <Img fluid={article.contentfulArticle.heroImage.fluid}/>
    </div>
  </Layout>
);

export const query = graphql`
  query($slug: String!) {
    contentfulArticle(slug: {eq: $slug}) {
      description
      title
      publishDate(formatString:"MMMM Do, YYYY")
      author {
        name
        title
        email
      }
      seoPageTitle
      description
      body {
        raw
      }
      heroImage {
        fluid(maxWidth: 800) {
           ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default ArticleTemplate;
